import React from 'react';



function Help(){
    return (
        <>
            <h1>This is the help page</h1>
        </>
    );
}

export default Help;